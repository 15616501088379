// src/SignIn.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useAuth } from '../AuthContext';
import logoImage from '../static/images/uzh.svg';
import asknaturelogo from '../static/images/asknaturelogo.png';

const Home = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const { auth } = useAuth(); // Use the useAuth hook to get the auth state


    return (
        <div className="container-fluid">
            <nav className="navbar navbar-expand-lg bg-transparent" aria-label="Offcanvas navbar large">
                <div className="container my-0">
                    <a className="navbar-brand" href="/">  <img src={asknaturelogo} alt="AskNature Logo" 
        style={{ width: '150px', height: 'auto' }} 
/>
    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-end text-bg-dark" tabIndex="-1" id="offcanvasNavbar2" aria-labelledby="offcanvasNavbar2Label">
                        <div className="offcanvas-header">
            
                            <button type="button" className="btn-close btn-close-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-center align-items-center flex-grow-1 py-3">
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" target="_blank" href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4860331">Publications</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" target="_blank" href="https://www.chatclimate.ai/team">Team</a>
                                </li>

                                {auth ? (
                                    // Render dashboard link if logged in
                                    <li className="nav-item">
                                        <a className="nav-link primarybutton" href="/dashboard">Dashboard</a>
                                    </li>
                                ) : (
                                    // Render sign in and sign up links if not logged in
                                    <>
                                        <li className="nav-item">
                                            <a className="nav-link secondarybutton" href="/signin">Sign In</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link primarybutton" href="/signup">Sign Up</a>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="bg-video-wrapper">
                <div className="green-gradient"></div>
                <div className="w-embed">
                    <video autoPlay muted loop     style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
                    >
                        <source src="https://d34p663dzicvaw.cloudfront.net/65ae8ba51dff7302e1a82996_output.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div className='col-sm-8 mx-auto text-center  py-5 specialcol'>
            <div className="hero-content mx-auto my-2"><h1 className="hero-heading mx-auto py-5">Ask from Corporates’ Nature-related Disclosures</h1>
            <div className="hero-subheading text-center">Enhance efficiency in analyzing Corporate Sustainability reports by summarizing content, extracting key data, and synthesizing key findings.<br/></div>
            <a href="/signup" className="primarybuttonother mx-auto my-4">Sign Up</a>
            </div>
            <img src={logoImage} className='bottom-right-img' alt='Partnerlogo' />

            </div>


        </div>
    
    );
};

export default Home;
