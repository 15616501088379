import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Database = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the /database endpoint
    axios.get('https://chattnfd.app/userupload/database')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h1 className="card-title">Corporate Sustainability Reports examples</h1>
          <p className="card-text">
          Here are some corporate sustainability reports that were analyzed by asknature.ai:
          </p>

          {/* Responsive table */}
          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Source</th>
                  <th>Report</th>
                  <th>Number of pages</th>
       
                  <th>Verification Status</th>
                </tr>
              </thead>
              <tbody>
                {data.slice().reverse().map((item, index) => ( // Reverse the data here
                  <tr key={index}>
                    <td>
                      <a 
                        href={`https://chattnfd.app/userupload${item.link}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <img 
                          src={`https://chattnfd.app/userupload${item.thumbnail_link}`} 
                          alt={item.name || "Thumbnail"} 
                          style={{ width: "60px", height: "auto" }}
                        />
                      </a>
                    </td>
                    <td>
                      <a 
                        href={`https://chattnfd.app/userupload${item.link}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        {item.name ? item.name : "Unnamed Document"}
                      </a>
                    </td>
                    <td>{item.total_pages}</td>
                    <td>{item.verification}</td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Database;
