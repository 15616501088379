// AttachChatBox.js

import React, { useState } from 'react';
import './ChatBox.css'; // Custom styling
import { Oval } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paperPlane from '../static/images/paper-plane.png'; // Import the image
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

const AttachChatBox = () => {
    const [message, setMessage] = useState('');
    const [askedquestion, setAskedquestion] = useState('');

    const [files, setFiles] = useState([]);
    const [response, setResponse] = useState('');
    const [references, setReferences] = useState([]);
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false); // Added state for processing
    const [error, setError] = useState(null);
    const [showReferences, setShowReferences] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const useremail = localStorage.getItem('useremail');
    const sessionid = localStorage.getItem('token');

    console.log(useremail);

    const handleClick = (e) => {
        e.preventDefault();
        handleSubmit(e);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!message) return;

        const requestData = {
            question: message,
            similarity_top_k: 10,
            useremail: useremail,
            sessionid: sessionid
        };

        setLoading(true);
        setError(null);
        try {
            const response = await fetch('https://asknature.ai/qauser/query', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            const responseData = await response.json();
            console.log(responseData);

            // Split response text and references
            const responseText = responseData["Response from simple LLM prompting with references"];
            const referencesStartIndex = responseText.indexOf("References:");
            const mainResponse = responseText.substring(0, referencesStartIndex).trim();
            const referencesText = responseText.substring(referencesStartIndex).trim();

            // Extract references
            const referencesArray = referencesText.split('\n').slice(1).map(ref => ref.trim()).filter(ref => ref);

            // Clear the message input after sending
            setAskedquestion(message);
            setMessage('');
            setNewMessage('');
            setResponse(mainResponse);
            setReferences(referencesArray);
        } catch (err) {
            console.error(err);
            setError('Failed to send message');
        } finally {
            setLoading(false);
        }
    };

    const handleFileUpload = async (selectedFiles) => {
        const formData = new FormData();
        for (const file of selectedFiles) {
            formData.append('file', file);
        }
        formData.append('chunk_size', '512');
        formData.append('question_answered_extractor', 'false');
        formData.append('useremail', useremail);
        formData.append('sessionid', sessionid);

        setLoading(true);
        setProcessing(true); // Set processing to true
        setError(null);
        try {
            const response = await fetch('https://asknature.ai/userupload/upload-or-fetch-pdf/', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to upload files');
            }

            const responseData = await response.json();
            console.log(responseData);

            const taskId = responseData.task_id;
            setNewMessage('Files uploaded successfully, processing started...');

            // Start polling for processing status
            checkStatus(taskId);

            // Clear the file input after uploading
            setFiles([]);
        } catch (err) {
            console.error(err);
            setError('Failed to upload files');
            setProcessing(false); // Set processing to false on error
        } finally {
            setLoading(false);
        }
    };

    const checkStatus = async (taskId) => {
        try {
            const response = await fetch(`https://asknature.ai/userupload/processing-status/${taskId}`);
            const data = await response.json();
            console.log(data);
            if (data.status === "Completed") {
                // Inform the user that processing is complete
                setNewMessage('Files processed successfully, now you can chat here.');
                setProcessing(false);
                setLoading(false);
            } else if (data.status.startsWith("Failed")) {
                setError(`Processing failed: ${data.status}`);
                setProcessing(false);
                setLoading(false);
            } else {
                // Still processing; poll again after a delay
                setTimeout(() => checkStatus(taskId), 5000); // Poll every 5 seconds
            }
        } catch (err) {
            console.error(err);
            setError('Failed to check processing status');
            setProcessing(false);
            setLoading(false);
        }
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length) {
            setFiles(selectedFiles);
            handleFileUpload(selectedFiles);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const toggleReferences = () => {
        setShowReferences(!showReferences);
    };

    return (
        <div className="halfsize">
            <div className='row'>
                <h2 className='text-center'>Ask from Corporate Sustainability Reports (CSRs)</h2>
            </div>
            {response && (
                <div className="response-container">
                    <h5>Question: {askedquestion}</h5>
                    <h4>Response:</h4>
                    <pre>{response}</pre>
                    {references.length > 0 && (
                        <details>
                            <summary>References</summary>
                            <ul>
                                {references.map((ref, index) => (
                                    <li key={index}>{ref}</li>
                                ))}
                            </ul>
                        </details>
                    )}
                </div>
            )}
            {(loading || processing) && (
                <div className="loading-container">
                    <Oval
                        height={50}
                        width={50}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                    {processing && <p> please wait...</p>}
                </div>
            )}
            {error && (
                <div className="error-container">
                    <p>{error}</p>
                </div>
            )}
            <p>{newMessage}</p>
            <div className="chatbox">
                <button className="attach-button" onClick={() => document.getElementById('fileInput').click()}><FontAwesomeIcon icon={faPaperclip} /></button>
                <input
                    type="text"
                    placeholder="Ask your questions from CSRs (upload PDF)."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <img src={paperPlane} className='submit' onClick={handleClick} alt="Submit" />
                <input
                    type="file"
                    className="form-control chatbox-file-input"
                    id="fileInput"
                    onChange={handleFileChange}
                    multiple
                    style={{ display: 'none' }}
                />
            </div>
        </div>
    );
};

export default AttachChatBox;
